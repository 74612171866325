<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <span class="display-4 col-6">
        <h1>{{ `${categoryEquipment.name}` }}</h1>
      </span>
      <div class="col-6 text-right"></div>
    </div>

    <div class="row mb-5">
      <div class="col-12">
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.NAME") }}</dt>
          <dd class="col-sm-8">
            {{ categoryEquipment.name }}
          </dd>
        </dl>

        <dl class="row" v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)">
          <dt class="col-sm-4">{{ $t("COMMON.ORGANIZATION") }}</dt>
          <dd class="col-sm-8">
            <organization :organization="categoryEquipment.organization" />
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd class="col-sm-8">
            {{ $timeZoneDateFormat(categoryEquipment.created_at) }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd class="col-sm-8">
            {{ $timeZoneDateFormat(categoryEquipment.updated_at) }}
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import IconCheck from "@/components/IconCheck.vue";

export default {
  name: "cash-register-view-global",
  components: { IconCheck },

  props: ["categoryEquipment"],

  data() {
    return {};
  },

  created() {},

  methods: {
    categoryEquipmentUpdated() {
      this.$emit("categoryEquipmentUpdated", true);
    },
  },

  mounted() {},

  watch: {
    categoryEquipment(categoryEquipment) {},
  },
};
</script>
